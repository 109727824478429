import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';

export const ORDER_FRAGMENT = /* GraphQL */ `
	fragment OrderInfo on Order {
		id
		email
		authorizeProductSubstitution
		orderDate
		status
		grossClientShippingPrice
		grossMerchantsShippingPrice
		grossTotalDiscounts
		grossTotalPrice
		shippingAddress1
		shippingAddress2
		shippingCity
		shippingCountry
		shippingName
		shippingNotes
		shippingPhone
		shippingProvince
		shippingVat
		shippingZip
		User {
			id
			firstName
			lastName
			email
		}
		Deliveries {
			number
			deliveryType
			status
			logisticsAgent
			logisticsData
			MerchantShippings {
				note
				packages
				status
				number
				weight
				logisticsData
				updatedAt
				Merchant {
					id
					locale
					commercialName
					logisticsAddress1
					logisticsAddress2
					logisticsCity
					logisticsCountry
					logisticsObservations
					logisticsPhone
					logisticsProvince
					logisticsZip
				}
				OrderItems {
					id
					grossDiscountedPrice
					Variant {
						id
						discountedPrice
						price
						sku
						barcode
						isAvailable
						outOfSeasson
						limitedProduction
						title
						weight
						weightUnit
						Product {
							id
							locale
							title
							SpecialFeatures {
								id
							}
						}
					}
					grossPrice
					productTitle
					boughtQuantity
					sent
					sku
					barcode
					substituted
					sentQuantity
				}
			}
		}
		Events {
			id
			action
			category
			details
			message
			success
			createdAt
		}
	}
`;

export const REFUND_FRAGMENT = /* GraphQL */ `
	fragment RefundInfo on Order {
		id
		email
		authorizeProductSubstitution
		orderDate
		status
		shippingAddress1
		shippingAddress2
		shippingCity
		shippingCountry
		shippingName
		shippingNotes
		shippingPhone
		grossClientShippingPrice
		grossMerchantsShippingPrice
		shippingProvince
		shippingVat
		shippingZip
		grossTotalPrice
		grossTotalDiscounts
		User {
			id
			firstName
			lastName
			email
		}
		RefundItems {
			id
			refundRequestedQuantity
			refundStatus
			MerchantShippingNumber
			Variant {
				id
				title
				Product {
					id
					locale
					title
				}
			}
		}
	}
`;

const ORDER_QUERY = gql`
	query Order($orderId: ID!) {
		Order(orderId: $orderId) {
			...OrderInfo
		}
	}
	${ORDER_FRAGMENT}
`;

const ORDERS_QUERY = gql`
	query MerchantOrders($userId: ID, $scope: String, $order_scope: String, $limit: Int, $offset: Int) {
		MerchantOrders(userId: $userId, scope: $scope, order_scope: $order_scope, limit: $limit, offset: $offset) {
			... on OrdersList {
				count
				hasMore
				List {
					id
					grossClientShippingPrice
					grossMerchantsShippingPrice
					grossTotalDiscounts
					grossTotalPrice
					hasIncident
					shippingNotes
					orderDate
					status
					User {
						id
						firstName
						lastName
						email
					}
					Merchants {
						id
						locale
						commercialName
						administrativeBusinessName
					}
					Deliveries {
						status
						number
						MerchantShippings {
							grossTotalPrice
						}
					}
				}
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
			}
		}
	}
`;

const CONFIRMMERCHANTSHIPPING_MUTATION = gql`
	mutation ConfirmMerchantShippingMutation(
		$OrderId: ID!
		$MerchantId: ID!
		$DeliveryNumber: String!
		$orderItems: [orderItemsInput]!
		$emptyShipping: Boolean
	) {
		ConfirmMerchantShipping(
			OrderId: $OrderId
			MerchantId: $MerchantId
			DeliveryNumber: $DeliveryNumber
			orderItems: $orderItems
			emptyShipping: $emptyShipping
		) {
			... on Success {
				success
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
				user
			}
			... on StripeFault {
				message
				stripeAccountId
			}
			... on MailFault {
				message
				emailRecipient
			}
		}
	}
`;

const PREPAREMERCHANTSHIPPING_MUTATION = gql`
	mutation PrepareMerchantShippingMutation(
		$MerchantShippingNumber: String!
		$merchantShippingInput: MerchantShippingInput!
	) {
		PrepareMerchantShipping(
			MerchantShippingNumber: $MerchantShippingNumber
			merchantShippingInput: $merchantShippingInput
		) {
			... on Success {
				success
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
				user
			}
			... on MailFault {
				message
				emailRecipient
			}
		}
	}
`;

const MERGEDELIVERIES_MUTATION = gql`
	mutation MergeDeliveriesMutation($orderItems: [orderItemsInput]!) {
		MergeDeliveries(orderItems: $orderItems) {
			... on MergeDeliverySuccess {
				merchantShippingNumber
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
				user
			}
		}
	}
`;

const COMPLETEMERCHANTSHIPPING_MUTATION = gql`
	mutation CompleteMerchantShippingMutation($MerchantShippingNumber: String!) {
		CompleteMerchantShipping(MerchantShippingNumber: $MerchantShippingNumber) {
			... on Success {
				success
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
				user
			}
		}
	}
`;

const REFUND_QUERY = gql`
	query Order($orderId: ID!) {
		Order(orderId: $orderId) {
			...RefundInfo
		}
	}
	${REFUND_FRAGMENT}
`;

const REFUNDS_QUERY = gql`
	query MerchantOrders($scope: String, $order_scope: String, $limit: Int, $offset: Int) {
		MerchantOrders(scope: $scope, order_scope: $order_scope, limit: $limit, offset: $offset) {
			... on OrdersList {
				count
				hasMore
				List {
					id
					orderDate
					User {
						id
						firstName
						lastName
					}
					Merchants {
						id
						locale
						commercialName
					}
					RefundItems {
						id
						grossPrice
						grossDiscountedPrice
						refundRequestedQuantity
						refundStatus
					}
				}
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
				user
			}
		}
	}
`;

const UPDATE_REFUND_MUTATION = gql`
	mutation UpdateRefund($items: [updateRefundInput!], $status: String) {
		UpdateRefund(items: $items, status: $status) {
			... on Order {
				...RefundInfo
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
				user
			}
		}
	}
	${REFUND_FRAGMENT}
`;

const UPDATE_ORDER_NOTES = gql`
	mutation UpdateOrderNotes($orderId: ID!, $shippingNotes: String!) {
		UpdateNotes(orderId: $orderId, shippingNotes: $shippingNotes) {
			... on Order {
				...OrderInfo
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
				user
			}
		}
	}
	${ORDER_FRAGMENT}
`;

export const useOrder = orderId => useQuery(ORDER_QUERY, { variables: { orderId }, fetchPolicy: 'network-only' });
export const useLazyOrder = variables => useLazyQuery(ORDER_QUERY, { variables, fetchPolicy: 'network-only' });
export const useLazyRefund = variables => useLazyQuery(REFUND_QUERY, { variables, fetchPolicy: 'network-only' });
export const useOrders = variables => useQuery(ORDERS_QUERY, { variables, fetchPolicy: 'network-only' });
export const useLazyOrders = variables =>
	useLazyQuery(ORDERS_QUERY, {
		variables,
		fetchPolicy: 'network-only',
	});
export const useConfirmMerchantShipping = props => useMutation(CONFIRMMERCHANTSHIPPING_MUTATION, props);
export const usePrepareMerchantShipping = props => useMutation(PREPAREMERCHANTSHIPPING_MUTATION, props);
export const useMergeDeliveries = props => useMutation(MERGEDELIVERIES_MUTATION, props);
export const useCompleteMerchantShipping = props => useMutation(COMPLETEMERCHANTSHIPPING_MUTATION, props);
export const useLazyRefunds = variables =>
	useLazyQuery(REFUNDS_QUERY, {
		variables,
		fetchPolicy: 'network-only',
	});
export const useUpdateRefund = props => useMutation(UPDATE_REFUND_MUTATION, props);
export const useUpdateOrderNotes = props => useMutation(UPDATE_ORDER_NOTES, props);

export default useOrders;
