const formatDate = (date, separator = '/') => {
	const year = date?.split('-')[0];
	const month = date?.split('-')[1];
	const day = date?.split('-')[2].slice(-0, 2);
	return day + separator + month + separator + year;
};

export const formatDateYYYYMMDD = (date, separator = '/') => {
	const year = date?.split('-')[0];
	const month = date?.split('-')[1];
	const day = date?.split('-')[2].slice(-0, 2);
	return year + separator + month + separator + day;
};

export const formatCurrentDateDDMMYYYY = () => {
	const today = new Date();
	const yyyy = today.getFullYear();
	let mm = today.getMonth() + 1;
	let dd = today.getDate();

	if (dd < 10) dd = '0' + dd;
	if (mm < 10) mm = '0' + mm;

	return dd + '/' + mm + '/' + yyyy;
};

export default formatDate;
