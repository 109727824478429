import React from 'react';
import { Page, Document, StyleSheet, View, Image, Text } from '@react-pdf/renderer';
import JsBarcode from 'jsbarcode';
import { formatCurrentDateDDMMYYYY } from 'lib/formatDate';

// Create styles
const styles = StyleSheet.create({
	page: {
		backgroundColor: '#FFF',
		fontSize: '14',
		flexDirection: 'row',
		border: '1px solid #000',
		padding: '15px',
		height: '100%',
		width: '100%',
	},
	section: {
		flexDirection: 'column',
		flexBasis: '50%',
	},
});

const isRefrigerated = delivery => {
	return delivery?.MerchantShippings?.some(merchantShipping =>
		merchantShipping.OrderItems?.some(orderItem =>
			orderItem.Variant?.Product.SpecialFeatures?.some(sf => sf.id === '4')
		)
	);
};

// Create Document Component
const OrderPdfSeur = ({ orderData, delivery, merchantShipping, packageIndex, config }) => {
	const canvas = document.createElement('canvas');
	JsBarcode(canvas, merchantShipping?.logisticsData?.packages[packageIndex]?.id, {
		margin: 0,
		fontSize: 14,
		height: 75,
	});
	const barcode = canvas.toDataURL();

	return (
		<Document>
			<Page size={[582, 393]} style={{ padding: '3px' }}>
				<View style={styles.page}>
					<View style={{ ...styles.section, fontSize: 10, marginRight: '3px', letterSpacing: 1 }}>
						<View
							style={{
								flexDirection: 'column',
								justifyContent: 'space-between',
								flexBasis: '35%',
								paddingTop: '5px',
								paddingBottom: '5px',
								textTransform: 'uppercase',
							}}
						>
							<Text style={{ fontSize: 18, fontWeight: 'semibold' }}>{config?.marketplace_name}</Text>
							<View style={{ flexDirection: 'column', justifyContent: 'flex-end' }}>
								<Text style={{ marginBottom: '10px' }}>{config?.fiscal_data?.address}</Text>
								<Text style={{ marginBottom: '10px' }}>{config?.fiscal_data?.zip} Marratxí - ES</Text>
								<Text style={{ alignSelf: 'flex-end', marginRight: '30px' }}>
									CCC:{config.seur_logistics.account_number}
								</Text>
							</View>
						</View>

						<View
							style={{
								flexDirection: 'column',
								justifyContent: 'space-between',
								flexBasis: '35%',
								borderTop: '1px solid #000',
								paddingTop: '2px',
								paddingBottom: '2px',
								flexGrow: 1,
								textTransform: 'uppercase',
							}}
						>
							<Text>{orderData?.shippingName}</Text>
							<View style={{ flexDirection: 'column', justifyContent: 'flex-end' }}>
								<Text style={{ marginBottom: '15px' }}>
									{orderData?.shippingAddress1}{' '}
									{orderData?.shippingAddress2 ? orderData?.shippingAddress2 : ''}
								</Text>
								<Text style={{ marginBottom: '30px', fontSize: 14, letterSpacing: 0 }}>
									{orderData?.shippingZip} {orderData?.shippingProvince} -{' '}
									{orderData?.shippingCountry}
								</Text>
								<Text style={{ textTransform: 'capitalize' }}>Att:</Text>
							</View>
						</View>
						<View
							style={{
								flexDirection: 'column',
								justifyContent: 'flex-end',
							}}
						>
							<View
								style={{
									flexDirection: 'column',
									justifyContent: 'space-between',
									borderTop: '1px solid #000',
									paddingTop: '2px',
									height: '95px',
								}}
							>
								<View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
									<Text>Ref.Exp:</Text>
									<Text style={{ fontSize: 14, marginLeft: '15px' }}>{delivery?.number}_pda</Text>
								</View>
								<View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
									<Text>Ref.Bul:</Text>
									<Text style={{ fontSize: 14, marginLeft: '15px' }}>
										{merchantShipping?.number?.split('_').pop()}
									</Text>
								</View>
								<View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
									<Text>Obs:</Text>
									<Text style={{ fontSize: 10, marginLeft: '15px' }}>{orderData?.shippingNotes}</Text>
								</View>
							</View>
						</View>
					</View>

					<View style={styles.section}>
						<View style={{ textTransform: 'uppercase' }}>
							<View style={{ flexDirection: 'row', border: '1px solid #000', height: '45px' }}>
								<View
									style={{
										flexBasis: '50%',
										borderRight: '1px solid #000',
										display: 'flex',
										alignItems: 'center',
										paddingTop: '5px',
									}}
								>
									<Text style={{ fontSize: 20, fontWeight: 'semibold' }}>
										{isRefrigerated(delivery) ? 'S-48/FRIO' : '*B2C/ESTD'}
									</Text>
								</View>
								<View
									style={{
										flexBasis: '50%',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<Text style={{ fontSize: 14 }}>SEUR</Text>
								</View>
							</View>
							<View style={{ flexDirection: 'row', lineHeight: '0.9' }}>
								<View style={{ flexDirection: 'row', flexBasis: '50%' }}>
									<Text style={{ textTransform: 'lowercase' }}>de </Text>
									<Text>Marratxí</Text>
								</View>
								<View style={{ flexDirection: 'row', flexBasis: '50%', marginLeft: '10px' }}>
									<Text style={{ textTransform: 'lowercase' }}>a </Text>
									<Text>{orderData?.shippingProvince}</Text>
								</View>
							</View>
						</View>
						<Text
							style={{
								height: '60px',
								fontSize: 40,
								fontWeight: 'bold',
								letterSpacing: -2,
								textTransform: 'uppercase',
							}}
						>
							{delivery?.logisticsData?.outputPlatform}
						</Text>
						<Image src={barcode} />
						<View style={{ flexGrow: 1, flexDirection: 'column', justifyContent: 'flex-end' }}>
							<View
								style={{
									flexDirection: 'row',
									border: '1px solid #000',
									height: '95px',
								}}
							>
								<View
									style={{ borderRight: '1px solid #000', flexDirection: 'column', flexBasis: '75%' }}
								>
									<View
										style={{
											flexDirection: 'row',
											borderBottom: '1px solid #000',
											padding: '5px',
											flexBasis: '60%',
										}}
									>
										<View
											style={{
												flexDirection: 'column',
												justifyContent: 'space-between',
												flexBasis: '55%',
												fontSize: 10,
												letterSpacing: 1,
											}}
										>
											<Text>
												Bulto:{' '}
												{merchantShipping?.logisticsData?.packages[packageIndex]?.number ?? 1}
											</Text>
											<Text>Peso: {merchantShipping?.weight ?? 0}</Text>
											<Text>Fecha impresión:</Text>
										</View>
										<View
											style={{
												flexDirection: 'column',
												flexBasis: '45%',
												justifyContent: 'space-between',
												fontWeight: 'semibold',
											}}
										>
											<Text>P.PAGADOS</Text>
											<Text>{formatCurrentDateDDMMYYYY()}</Text>
										</View>
									</View>
									<View style={{ flexDirection: 'row', flexBasis: '40%' }}>
										<View style={{ borderRight: '1px solid #000', flexBasis: '25%' }} />
										<View style={{ borderRight: '1px solid #000', flexBasis: '25%' }} />
										<View style={{ borderRight: '1px solid #000', flexBasis: '25%' }} />
										<View style={{ flexBasis: '25%' }} />
									</View>
								</View>
								<View
									style={{
										flexBasis: '25%',
										display: 'flex',
										justifyContent: 'center',
										paddingLeft: '5px',
									}}
								>
									<Text style={{ fontSize: 48 }}>
										{isRefrigerated(merchantShipping) ? '61' : '11'}
									</Text>
								</View>
							</View>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	);
};
export default OrderPdfSeur;
